/**
 * 常用验证
 * 2022年5月7日
 */

//验证密码
export const verifyCode = (text) => {
  if (text === undefined || text === null || text === "") {
    return true;
  }
  return text.toString().length === 6;
};

//验证邮箱
export const verifyEmail = (text) => {
  const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return emailReg.test(text);
};

//验证手机
export const verifyPhone = (text) => {
  const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  return phoneReg.test(text);
};

//6到100位数的(数字，符号，字母)任意组合密码
export const verifyPwd = (text) => {
  let validCount = 0
  const validLength = text.length >= 8 && text.length <= 100
  const patter1 = /[_!@#$%*]/
  const patter2 = /[A-Za-z]+/
  const patter3 = /[0-9]+/
  validCount = validCount + (patter1.test(text) ? 1 : 0)
  validCount = validCount + (patter2.test(text) ? 1 : 0)
  validCount = validCount + (patter3.test(text) ? 1 : 0)

  return validLength && validCount >= 2
  // const pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-zA-Z]|[0-9]){5,100}$/;
  // return pwdReg.test(text);
};

//是否空
export const isEmpty = (text) => {
  return text === undefined || text === null || text === "";
};
