import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_en from "./locales/en.json";
import translation_cn from "./locales/zh-Hans.json";
import translation_hk from "./locales/zh-Hant.json";
import translation_ru from "./locales/ru.json";
import translation_pt from "./locales/pt.json";
import translation_vi from "./locales/vi.json";
import translation_jp from "./locales/jp.json";
import translation_ko from "./locales/ko.json";
import translation_fr from "./locales/fr.json";
import translation_ba from "./locales/ba.json";
import translation_es from "./locales/es.json";

const resources = {
  en: {
    translation: translation_en,
  },
  cn: {
    translation: translation_cn,
  },
  hk: {
    translation: translation_hk,
  },
  ru: {
    translation: translation_ru,
  },
  pt: {
    translation: translation_pt,
  },
  vi: {
    translation: translation_vi,
  },
  jp: {
    translation: translation_jp,
  },
  fr: {
    translation: translation_fr,
  },
  ko: {
    translation: translation_ko,
  },
  es: {
    translation: translation_es,
  },
  ba: {
    translation: translation_ba,
  },

};

i18n.use(initReactI18next).init({
  resources,
  lng: "cn",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
