/**
 * 官网head
 * 2022年5月7日
 */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate
} from 'react-router-dom';
import { useWindowSize } from "../../hooks/useWindowSize";
import { language, imgUrl } from "../../utils/help";
import { Dropdown } from "antd"
import i18n from "i18next";


const logo = imgUrl + "shareTttile.png"
const lang = imgUrl + "lang.png"
const singUpImg = imgUrl + "singUp.png"


export default function Head(props) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { isMobile, width } = useWindowSize();
  const [langImg, setLangImg] = useState("");
  const navigate = useNavigate()
  const langArr = [
    { title: "English", id: "En" },
    { title: "简体中文", id: "Cn" },
    { title: "繁體中文", id: "Hk" },
    { title: "Português", id: "Pt" },
    { title: "Bahasa Indonesia", id: "Id" },
    // { title: "हिन्दी", id: "Hi" },
    { title: "русский", id: "Ru" },
    { title: "Tiếng việt", id: "Vi" },
    { title: "日文", id: "Ja" },
    // { title: "ไทย", id: "Th" },
    { title: "한국어", id: "Ko" },
    // { title: "Bahasa Melayu", id: "Ms" },
  ];


  const items = [
    {
      key: 'en',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("en")}>
          <img src={require(`../../assets/img/nation/en.png`)} style={{ width: 24, height: 24 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>English</div>
        </div>
      ),
    },
    {
      key: 'pt',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("pt")}>
          <img src={require(`../../assets/img/nation/pt.png`)} style={{ width: 24, height: 20 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>Português</div>
        </div>
      ),
    },
    {
      key: 'ba',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("ba")}>
          <img src={require(`../../assets/img/nation/ba.png`)} style={{ width: 24, height: 24 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>Bahasa Indonesia</div>
        </div>
      ),
    },
    {
      key: 'fr',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("fr")}>
          <img src={require(`../../assets/img/nation/fr.png`)} style={{ width: 24, height: 20 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>Français</div>
        </div>
      ),
    },
    {
      key: 'es',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("es")}>
          <img src={require(`../../assets/img/nation/es.png`)} style={{ width: 24, height: 20 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>Español</div>
        </div>
      ),
    },
    {
      key: 'ko',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("ko")}>
          <img src={require(`../../assets/img/nation/ko.png`)} style={{ width: 24, height: 24 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>한국어</div>
        </div>
      ),
    },
    {
      key: 'jp',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("jp")}>
          <img src={require(`../../assets/img/nation/jp.png`)} style={{ width: 24, height: 24 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>日文</div>
        </div>
      ),
    },
    {
      key: 'vi',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("vi")}>
          <img src={require(`../../assets/img/nation/vi.png`)} style={{ width: 24, height: 24 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>Tiếng Việt</div>
        </div>
      ),
    },
    {
      key: 'ru',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("ru")}>
          <img src={require(`../../assets/img/nation/ru.png`)} style={{ width: 24, height: 20 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>русский</div>
        </div>
      ),
    },

    {
      key: 'hk',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("hk")}>
          <img src={require(`../../assets/img/nation/hk.png`)} style={{ width: 24, height: 24 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>繁體中文</div>
        </div>
      ),
    },
    {
      key: 'cn',
      label: (
        <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", padding: 10 }} className="fNaEyC" onClick={(e) => seleLang("cn")}>
          <img src={require(`../../assets/img/nation/cn.png`)} style={{ width: 24, height: 24 }} />
          <div style={{ color: '#fff', fontWeight: "bold", fontSize: 16 }}>简体中文</div>
        </div>
      ),
    },

  ];

  const seleLang = (lan) => {
    localStorage.setItem("lan", lan)
    setLangImg(lan)
    i18n.changeLanguage(lan)
  }


  useEffect(() => {
    const lan = language();
    setLangImg(lan)
    i18n.changeLanguage(lan);
  }, []);

  const handleOpen = () => {
    const { handleOpen } = props
    handleOpen(true)
  }
  return (
    <>
      <div style={{ position: 'relative' }} >
        <div className="ceCePd">
          <img src={logo} onClick={() => navigate('/')} className="logo"></img>
          <div style={{ display: 'flex', alignContent: 'center' }}>
            <div className="selectLang" onClick={handleOpen} style={{ marginRight: 20 }}>
              <div style={{ color: '#fff' }}>{t("title")}</div>
            </div>
            <Dropdown
              menu={{
                items,

              }}
              placement="bottomLeft"
              overlayClassName="coGvvV"
            >
              <div className="selectLang">
                <img src={lang} style={{ width: 20, height: 20, marginRight: 10 }}></img>
                {langImg && <img src={require(`../../assets/img/nation/${langImg}.png`)} style={{ width: 20, height: 20 }}></img>}
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

    </>
  );
}


