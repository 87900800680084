// 官网首页
import React, { useEffect, useState, Component } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, withRouter } from 'react-router-dom';
import { useWindowSize } from "../hooks/useWindowSize";
import { language, getUrlCode, imgUrl, useRotuRreferralId } from "../utils/help";
import i18n from "i18next";
import { message } from "antd";
import Head from "../page/components/head"
import Food from "../page/components/foot"
import SingUpPage from "./register"
import queryString from "query-string";

const googlePlay = imgUrl + "googlePlay.png"
const android = imgUrl + "android.png"
const apple = imgUrl + "apple.png"
const hedge = imgUrl + "hedge.png"
const hedge1 = imgUrl + "hege1.png"
const binence = imgUrl + "binance.png"
const bitget = imgUrl + "bitget.png"
const bybit = imgUrl + "bybit.png"
const okx = imgUrl + "okx.png"
const huobi = imgUrl + "huobi1.png"
const kucoin = imgUrl + "kucoin.png"
const gateio = imgUrl + "gateio.png"
const monthly = imgUrl + "monthly.png"
const yearly = imgUrl + "yearly.png"
const securityIcon = imgUrl + "securityIcon.png"
const tradingIcon = imgUrl + "tradingIcon.png"
const hedgeIcon = imgUrl + "hedgeIcon.png"
const trailingtradingIcon = imgUrl + "trailingtradingIcon.png"
const bonusesup = imgUrl + "bonusesup.png"
const updatesand = imgUrl + "updatesand.png"
const fastcustom = imgUrl + "fastcustom.png"
const multilingual = imgUrl + "multilingual.png"
const photo1 = imgUrl + "photo1.jpg"
const photo2 = imgUrl + "photo2.jpg"
const bannemockup = imgUrl + "bannemockup1.png"


export default function Home(props) {
  const { t } = useTranslation();
  const [isCn, setCn] = useState(false);
  const [showSingUp, setIsSingUp] = useState(false);
  const { width, height, isMobile } = useWindowSize()
  const [invitationCode, setInvitationCode] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const isReferralIdPage = useRotuRreferralId()
  useEffect(() => {
    const lan = language();
    i18n.changeLanguage(lan)
    let query = window.location.search
    if (query && isReferralIdPage) {
      const queryObj = queryString.parse(query)
      let code
      queryObj.code && (code = queryObj.code)
      queryObj.CODE && (code = queryObj.CODE)
      if (code) {
        const referralParam = code.toUpperCase()
        if (referralParam.length != 6) return
        setIsSingUp(true)
        setInvitationCode(referralParam)
      }
    } else {
      if (isReferralIdPage) return
      const pathname = window.location.pathname
      const referralId = pathname.replace("/", "").toUpperCase()
      setIsSingUp(true)
      setInvitationCode(referralId)
    }
  }, []);

  const handleClose = (type, isShowSuccess) => {
    setIsSingUp(type)
    if (isShowSuccess == 1) {
      messageApi.open({
        type: "success",
        content: t('regSendSucc'),
      });
    }
  }
  const handleOpen = (type) => {
    setIsSingUp(type)
  }
  const openUrl = (url) => {
    window.open(url)
  }
  return (
    <div style={{ position: 'relative', height: '100vh', overflow: showSingUp ? 'hidden' : 'auto' }} >
      {contextHolder}
      {
        // 注册页面弹框
        showSingUp &&
        (
          <div className="fZKSu">
            <SingUpPage handleClose={handleClose} code={invitationCode} />
          </div>
        )
      }
      <div className="eHSsyh">
        <Head handleOpen={handleOpen} />
        <div className="dNzWzy"></div>
        <div className="bXnDvb "></div>
        <div className="cDDgVz"></div>
        <div className="jHdieN"></div>
        <div className="haRxZf">
          <svg style={{ position: "absolute" }}>
            <clipPath id="clip">
              <path d="M1175.65 35.7644C962.846 -46.1285 865.751 35.6288 643.768 54.6084C421.786 73.588 391.68 33.0436 226.292 14.4975C60.9034 -4.0486 11.1228 90.8729 -67.9998 110.654C-67.9998 198.586 -67.9998 516 -67.9998 516L1508.19 516L1508.19 8.45359C1508.19 8.45359 1388.84 117.807 1175.65 35.7644Z">
              </path>
            </clipPath>
          </svg>
        </div>
      </div>

      <div className="ceCePd1" style={{ top: 100, paddingRight: 10 }}>
        <div>
          {/* 产品介绍 */}
          <div className="hIBkFg">{t("integrateInto")}</div>
          <div className="tradelike" style={{ marginLeft: 10, marginRight: 10 }}>
            <div style={{ color: "#fff", marginTop: 40, fontSize: 20 }}>{t("tradeLik")}</div>
            <div style={{ color: "#fff", marginTop: 40, fontSize: 20 }}>{t("crypto")}</div>
          </div>
          {/* 下载 */}
          <div style={{ display: "flex", alignContent: "center", marginTop: 60 }}>
            <img src={googlePlay} style={{ width: 160, height: 60, cursor: "pointer" }} className="iconHover" onClick={() => openUrl("https://play.google.com/store/apps/details?id=ea.Ottradex.org")}></img>
            <img src={apple} style={{ width: 160, height: 60, marginLeft: 20, cursor: "pointer" }} className="iconHover" onClick={() => openUrl("https://testflight.apple.com/join/onBupwDI")}></img>
            <img src={android} style={{ width: 160, height: 60, marginLeft: 20, cursor: "pointer" }} className="iconHover" onClick={() => openUrl("https://d3q8zm1xoth3l.cloudfront.net/ant/install/android/ottrade.apk")}></img>
          </div>
        </div>
        {/* banner */}
        {
          !isMobile && (
            <>
              <div style={{ marginTop: isMobile ? 80 : 0, width: 355, height: 590, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: 900 }} src={bannemockup}></img>
              </div>
            </>
          )
        }
      </div>
      <div className="jMbBOM" style={{ marginTop: isMobile ? -80 : -60 }}>
        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
          <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: -20 }} className="powerfulWidth">
            <div style={{ fontSize: 32, color: '#fff', fontWeight: "bold", textAlign: "center" }}>{t("powerful")}</div>
            <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", marginTop: 80, flexWrap: 'wrap' }}>
              <div style={{ position: "relative" }}>
                <img src={hedge1} style={{ width: 360, height: 440 }}></img>
                <div style={{ position: "absolute", top: 20, left: 0, right: 0 }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: 30, paddingRight: 30 }}>
                    <div style={{ fontSize: 24, color: '#fff', fontWeight: "bold" }}>{t("security")}</div>
                    <img src={securityIcon} style={{ height: 83, width: 60 }} />
                  </div>
                  <div style={{ paddingLeft: 30, paddingRight: 30, marginTop: 26 }}>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", lineHeight: 1.4 }}>{t("protection")}</div>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", marginTop: 20, lineHeight: 1.4 }}>{t("safeguards")}</div>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", marginTop: 20, lineHeight: 1.4 }}>{t("employ")}</div>
                  </div>
                </div>
              </div>

              <div style={{ position: "relative" }}>
                <img src={hedge} style={{ width: 360, height: 440 }}></img>
                <div style={{ position: "absolute", top: 20, left: 0, right: 0 }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: 30, paddingRight: 30 }}>
                    <div style={{ fontSize: 24, color: '#fff', fontWeight: "bold" }}>{t("variety")}</div>
                    <img src={tradingIcon} style={{ height: 95, width: 60 }} />
                  </div>
                  <div style={{ paddingLeft: 30, paddingRight: 30, marginTop: 26 }}>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", lineHeight: 1.4 }}>{t("spotstrategies")}</div>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", marginTop: 20, lineHeight: 1.4 }}>{t("futurestrading")}</div>

                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", marginTop: 20, lineHeight: 1.4 }}>{t("cryptos")}</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', alignContent: "center", justifyContent: "space-between", marginTop: 40, flexWrap: 'wrap' }}>
              <div style={{ position: "relative" }}>
                <img src={hedge} style={{ width: 360, height: 440 }}></img>
                <div style={{ position: "absolute", top: 20, left: 0, right: 0 }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: 30, paddingRight: 30 }}>
                    <div style={{ fontSize: 24, color: '#fff', fontWeight: "bold" }}>{t("eutralMode")}</div>
                    <img src={hedgeIcon} style={{ height: 83, width: 60 }} />
                  </div>
                  <div style={{ paddingLeft: 30, paddingRight: 30, marginTop: 26 }}>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", lineHeight: 1.4 }}>{t("exclusive")}</div>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", marginTop: 20, lineHeight: 1.4 }}>{t("simultaneously")}</div>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", marginTop: 20, lineHeight: 1.4 }}>{t("realTime")}</div>
                  </div>
                </div>
              </div>

              <div style={{ position: "relative" }}>
                <img src={hedge1} style={{ width: 360, height: 440 }}></img>
                <div style={{ position: "absolute", top: 20, left: 0, right: 0 }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: 30, paddingRight: 30 }}>
                    <div style={{ fontSize: 24, color: '#fff', fontWeight: "bold" }}>{t("trading")}</div>
                    <img src={trailingtradingIcon} style={{ height: 83, width: 60 }} />
                  </div>
                  <div style={{ paddingLeft: 30, paddingRight: 30, marginTop: 26 }}>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", lineHeight: 1.4 }}>{t("automatically")}</div>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", lineHeight: 1.4, marginTop: 20 }}>{t("harness")}</div>
                    <div style={{ color: "#fff", fontSize: 14, fontWeight: "bold", marginTop: 20, lineHeight: 1.4 }}>{t("unfavorably")}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <div className="kNHqgu">
          <div className="jTMytU">
            <svg width={1440} height={699} viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="wave1"><path d="M1075 35.4323C1018.5 27.904 972.769 61.8428 910 26.9318C831.581 -16.6829 790.5 6.93181 669 6.93181C547.5 6.93181 484.744 59.6603 398.5 26.9319C328.97 0.546542 314 74 181.5 54C49 34 0.258789 34 0.258789 34C0.258789 120.822 0.25879 377.5 0.25879 377.5L1504.12 377.5L1504.12 69.4323C1504.12 69.4323 1335.5 77.4323 1278 69.4323C1220.5 61.4323 1203.09 87.6455 1167.5 69.4323C1126.21 48.2999 1131.5 42.9607 1075 35.4323Z" fill="url(#paint0_linear)"></path></clipPath></defs></svg>
          </div>
          <div className="jTXiRU">
            <svg width={1440} height={699} viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="wave2"><path d="M1043 16C986.5 8.47162 971.769 70.9109 909 35.9999C830.581 -7.61479 789.5 16 668 16C546.5 16 483.744 68.7285 397.5 36.0001C327.97 9.61469 283 55.9999 181.5 15.9999C80 -24.0001 0.258789 25.9999 0.258789 25.9999C0.258789 112.822 0.25879 339.5 0.25879 339.5L1504.12 339.5L1504.12 25.9999C1504.12 25.9999 1324.5 23.9999 1267 15.9999C1209.5 7.99991 1192.09 34.2131 1156.5 15.9999C1115.21 -5.1325 1099.5 23.5284 1043 16Z"></path></clipPath></defs></svg>
          </div>
          <div className="hDCskI ">
            <svg width={1440} height={699} viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="wave3"><path d="M1221 63.1469C1008.2 -18.7459 998.021 -3.8326 776.038 15.147C554.055 34.1266 485.426 81.6931 320.038 63.147C154.65 44.6009 67.0215 68.2247 -12.1011 88.0053C-12.1011 175.938 -12.1013 376.556 -12.1013 376.556L1510 376.556L1510 63.1469C1510 63.1469 1434.19 145.19 1221 63.1469Z"></path></clipPath></defs></svg>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 60 }}>
        <div style={{ maxWidth: 1230, marginTop: 300 }}>
          <div style={{ fontSize: 40, color: "#fff", textAlign: 'center', paddingLeft: 20, paddingRight: 20, paddingBottom: 30, fontWeight: 'bold' }}>{t("moreValue")}</div>
          <div className="advantages">
            <div className="gvnAxs">
              <img src={bonusesup} style={{ width: 148, height: 208 }} />
              <div style={{ fontSize: 18, color: '#fff', fontWeight: "bold", marginTop: 40, textAlign: 'center' }}>{t("referral")}</div>
            </div>
            <div className="efwLTn">
              <img src={updatesand} style={{ width: 161, height: 208 }} />
              <div style={{ fontSize: 18, color: '#fff', fontWeight: "bold", marginTop: 40, textAlign: 'center' }}>{t("regular")}</div>
            </div>
            <div className="hSsukS">
              <img src={fastcustom} style={{ width: 191, height: 191 }} />
              <div style={{ fontSize: 18, color: '#fff', fontWeight: "bold", marginTop: 40, textAlign: 'center' }}>{t("responsive")}</div>
            </div>
            <div className="lAsmb">
              <img src={multilingual} style={{ width: 170, height: 208 }} />
              <div style={{ fontSize: 18, color: '#fff', fontWeight: "bold", marginTop: 40, textAlign: 'center' }}>{t("multilingual")}</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <div className="cgmqud">
          <div className="QTgzF"></div>
          <div className="jFshNv"></div>
          <div className="eRXLNx"></div>
          <div className="hdkUYT"></div>
          <div className="ksZdMZ"></div>
          <div className="hScvBT">
            <svg><clipPath id="wave6"><path d="M1192 63.1469C979.197 -18.7459 969.021 -3.8326 747.038 15.147C525.055 34.1266 456.426 81.6931 291.038 63.147C125.65 44.6009 38.0215 68.2247 -41.1011 88.0053C-41.1011 175.938 -41.1013 376.556 -41.1013 376.556L1481 376.556L1481 63.1469C1481 63.1469 1405.19 145.19 1192 63.1469Z" ></path></clipPath></svg>
          </div>
        </div>
        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
          <div style={{ position: 'relative', top: 30 }}>
            <div style={{ marginTop: 60 }} className="runTrading">
              <div style={{ zIndex: 600, color: "#fff", fontSize: 40, textAlign: "center", fontWeight: 'bold', paddingLeft: 20, paddingRight: 20 }}>{t("bindExchange")}</div>
              {isMobile ? <MExchanges /> : <PcExchanges />}
              <div style={{ marginTop: 40 }}>
                <div style={{ zIndex: 800, color: "#fff", fontSize: 40, textAlign: "center", position: 'relative', paddingBottom: 40, fontWeight: 'bold', marginTop: 100 }} className="runTrading">{t("say")}</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", paddingRight: 10, paddingLeft: 10, marginTop: 40 }}>
                  <div className="fySLzt">
                    <img src={photo1} style={{ width: 60, height: 60, borderRadius: 60 }} />
                    <div style={{ color: '#fff', fontSize: 15, fontWeight: "500", marginTop: 20 }}> - Diego Costa</div>
                    <div style={{ color: '#fff', fontSize: 15, fontWeight: "500", lineHeight: 1.5, marginTop: 20 }}>{t("customizable")}</div>
                  </div>
                  <div className="fySLzt">
                    <img src={photo2} style={{ width: 60, height: 60, borderRadius: 60 }} />
                    <div style={{ color: '#fff', fontSize: 15, fontWeight: "500", marginTop: 20 }}> - Joseph Clark</div>
                    <div style={{ color: '#fff', fontSize: 15, fontWeight: "500", lineHeight: 1.5, marginTop: 20 }}>{t("tradingHas")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        // <div style={{ zIndex: 500, position: 'relative' }}>
        //   <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        //     <div style={{ marginTop: 100 }}>
        //       <div style={{ marginTop: 60 }}>
        //         {/* 订阅计划标题 */}
        //         <div style={{ zIndex: 600, color: "#fff", fontSize: 40, textAlign: "center", fontWeight: 'bold' }}>{t("subscriptionPlans")}</div>
        //         {/* 订阅计划详情 */}
        //         <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 60, flexWrap: "wrap", gap: 60 }}>
        //           {/* 月度 */}
        //           <div style={{ position: "relative" }}>
        //             <img src={hedge} style={{ width: 360, height: 420 }}></img>
        //             <div style={{ position: "absolute", top: 0, right: 0, left: 0 }}>
        //               <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column", width: 360, }}>
        //                 <div style={{ color: '#fff', fontSize: 26, fontWeight: "500", marginTop: 20 }}>{t("monthly")}</div>
        //                 {/* <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 8 }}>{t("perMonth")}</div> */}
        //                 <div style={{ color: '#fff', fontSize: 36, fontWeight: "bold", marginTop: 20 }}>$90</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "bold", marginTop: 20, textDecoration: 'line-through' }}>$150</div>
        //               </div>
        //               <div style={{ marginLeft: 20 }}>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 60, display: 'flex', alignItems: "flex-start", justifyContent: "flex-start", }}>{t("allStrategies")}</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 20, display: 'flex', alignItems: "flex-start", justifyContent: "flex-start", }}>{t("unlimited")}</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 20, display: 'flex', alignItems: "flex-start", justifyContent: "flex-start", }}>{t("day30")}</div>
        //               </div>
        //             </div>
        //           </div>
        //           {/* 季度 */}
        //           <div style={{ position: "relative", marginRight: isMobile ? 0 : 30, marginLeft: isMobile ? 0 : 30 }}>
        //             <img src={monthly} style={{ width: 360, height: 500 }}></img>
        //             <div style={{ position: "absolute", top: 0, right: 0, left: 0 }}>
        //               <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column", width: 360, }}>
        //                 <div style={{ color: '#fff', fontSize: 26, fontWeight: "500", marginTop: 20 }}>{t("quarterly")}</div>
        //                 {/* <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 8 }}>{t("months3")}</div> */}
        //                 <div style={{ color: '#fff', fontSize: 48, fontWeight: "bold", marginTop: 20 }}>$225</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "bold", marginTop: 20, textDecoration: 'line-through' }}>$450</div>
        //               </div>
        //               <div style={{ marginLeft: 20 }}>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 73, display: 'flex', alignItems: "center", justifyContent: "center", }}>{t("allStrategies")}</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 37, display: 'flex', alignItems: "center", justifyContent: "center", }}>{t("unlimited")}</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 37, display: 'flex', alignItems: "center", justifyContent: "center", }}>{t("day90")}</div>
        //               </div>
        //             </div>
        //           </div>
        //           {/* 年度 */}
        //           <div style={{ position: "relative" }}>
        //             <img src={yearly} style={{ width: 360, height: 420 }}></img>
        //             <div style={{ position: "absolute", top: 0, right: 0, left: 0 }}>
        //               <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column", width: 278, marginLeft: 78 }}>
        //                 <div style={{ color: '#fff', fontSize: 26, fontWeight: "500", marginTop: 60 }}>{t("Yearly")}</div>
        //                 {/* <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 8 }}>{t("perYear")}</div> */}
        //                 <div style={{ color: '#fff', fontSize: 36, fontWeight: "bold", marginTop: 20 }}>$720</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "bold", marginTop: 20, textDecoration: 'line-through' }}>$1800</div>
        //               </div>
        //               <div style={{ width: 278, marginLeft: 78 }}>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 60, display: 'flex', alignItems: "flex-start", justifyContent: "flex-start", }}>{t("allStrategies")}</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 20, display: 'flex', alignItems: "flex-start", justifyContent: "flex-start", }}>{t("unlimited")}</div>
        //                 <div style={{ color: '#fff', fontSize: 18, fontWeight: "400", marginTop: 20, display: 'flex', alignItems: "flex-start", justifyContent: "flex-start", }}>{t("day365")}</div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      }
      <Food />
    </div>
  );
};

const PcExchanges = () => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", paddingRight: 10, paddingLeft: 10 }}>
        <img src={binence} style={{ height: 40, zIndex: 800, marginTop: 140 }} />
        <img src={okx} style={{ height: 30, zIndex: 800, marginTop: 140 }} />
        <img src={bybit} style={{ height: 30, zIndex: 800, marginTop: 140 }} />
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
        <img src={gateio} style={{ height: 40, zIndex: 800, marginTop: 80, }} />
        <img src={bitget} style={{ height: 40, zIndex: 800, marginTop: 80, }} />
        <img src={kucoin} style={{ height: 40, zIndex: 800, marginTop: 80, }} />
        <img src={huobi} style={{ height: 40, zIndex: 800, marginTop: 80, }} />
      </div>
    </>
  )
}

const MExchanges = () => {
  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", marginTop: 30 }}>
        <img src={binence} style={{ width: 140, height: 30, zIndex: 800 }} />
        <img src={okx} style={{ width: 120, height: 30, zIndex: 800 }} />
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", marginTop: 30 }}>
        <img src={bybit} style={{ width: 120, height: 30, zIndex: 800 }} />
        <img src={gateio} style={{ width: 120, height: 30, zIndex: 800, }} />
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", marginTop: 30 }}>
        <img src={bitget} style={{ width: 120, height: 30, zIndex: 800, }} />
        <img src={kucoin} style={{ width: 120, height: 30, zIndex: 800, }} />
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", marginTop: 30 }}>
        <img src={huobi} style={{ width: 120, height: 30, zIndex: 800, }} />
      </div>
    </div>
  )
}