/**
 * 注册页面
 * 2022年5月7日
 */
import React, { useEffect, useState } from "react";
import Head from "../page/components/head"
import Food from "../page/components/foot"
import { imgUrl } from "../utils/help";
import { useWindowSize } from "../hooks/useWindowSize";
import {
  useNavigate
} from 'react-router-dom';
const logo = imgUrl + "shareTttile.png"

export default function Register(props) {
  const { width, height, isMobile } = useWindowSize()
  const navigate = useNavigate()

  return (
    <>
      <div style={{ alignContent: 'center', justifyContent: 'center', display: "flex" }}>
        <div style={{ width: isMobile ? 370 : 1300, color: 'rgba(205, 205, 205, .9)', marginLeft: 10, marginRight: 10 }}>
          <div style={{ alignContent: 'center', justifyContent: 'center', display: "flex", marginTop: 100, cursor: "pointer" }}> <img src={logo} onClick={() => navigate('/')} className="logo"></img></div>
          <div style={{ fontSize: 20, marginTop: 20, }}>
            We sincerely welcome you to visit Ottrade automated trading platform, hereinafter referred to as this platform, please read this user’s agreement carefully. Your actions on this platform will be considered to accept the following service agreements:
            Confirmation and acceptance of the terms of service of this platform
            The services provided by this platform will be strictly implemented in accordance with the published articles of incorporation, terms of service and operational rules. Users must fully agree to all terms of service and complete the registration process in order to become an official user of this platform.
          </div>
          <div style={{ marginTop: 20 }}>
            Confirmation and acceptance of the terms of service of this platform
            The services provided by this platform will be strictly implemented in accordance with the published articles of incorporation, terms of service and operational rules. Users must fully agree to all terms of service and complete the registration process in order to become an official user of this platform.
          </div>
          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Service Introduction</div>

          <div style={{ marginTop: 4 }}>
            This platform uses its own system to provide network services to users through the Internet. At the same time, the user must:
          </div>

          <div style={{ marginTop: 10 }}>
            (1) Equip yourself with the equipment needed for Internet access, including personal computers, modems or other necessary Internet devices.
          </div>

          <div style={{ marginTop: 10 }}>
            (2) Pay for the telephone and network expenses related to this service.
          </div>

          <div style={{ marginTop: 10 }}>
            Based on the importance of the network services provided by this platform, users should agree to:
          </div>

          <div style={{ marginTop: 10 }}>
            (1) Provide detailed and accurate personal information.
          </div>

          <div style={{ marginTop: 10 }}>
            (2)Continuously update the registration materials to meet timely, detailed and accurate requirements.
          </div>

          <div style={{ marginTop: 10 }}>
            This site does not disclose the user’s name, address, email address, and pen name, with the following exceptions:
          </div>

          <div style={{ marginTop: 10 }}>
            (1) The user authorizes this platform to disclose such information.
          </div>

          <div style={{ marginTop: 10 }}>
            (2) The corresponding laws and procedures require this platform to provide the user’s personal data. If the information provided by the user contains incorrect information, this platform reserves the right to terminate the user’s eligibility to use the network service.
          </div>

          <div style={{ marginTop: 10 }}>
            After confirming the identity opening permission, the user can obtain the corresponding product service provided by this platform Once the product is confirmed, other products cannot be replaced.
          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Modification of Service Terms And Service Revision</div>

          <div style={{ marginTop: 4 }}>
            this platform reserves the right to modify the terms of service as necessary. Once the terms of service of this platform have changed, the content will be prompted on the important page. If you do not agree with the changed content, the user can actively cancel the obtained network service. If the user continues to enjoy the web service, it is deemed to accept changes to the terms of service. this platform reserves the right to modify or discontinue the free service at any time without the need to notify the user. this platform exercises the right to modify or discontinue the free service without being responsible to the user or a third party.
          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>User Privacy System</div>

          <div style={{ marginTop: 4 }}>
            Respecting the privacy of users is a basic policy of this platform. Therefore, as a supplement to the analysis of the second personal registration data above, this platform will not disclose, edit or disclose its registration materials and other non-public content stored on this platform without the authorization of the legal user, unless there is a law. The licensing requirements or this platform believes that the disclosure of these letters is necessary in the following four situations on an honest basis:
          </div>

          <div style={{ marginTop: 10 }}>
            (1) Comply with the relevant laws and regulations and comply with the legal service procedures of this platform.
          </div>

          <div style={{ marginTop: 10 }}>
            (2) Maintain the trademark ownership of this platform.
          </div>

          <div style={{ marginTop: 10 }}>
            (3) Strive to maintain the privacy of users and the public in an emergency.
          </div>

          <div style={{ marginTop: 10 }}>
            (4) Meet other relevant requirements.
          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>User’s Account, Password and Security</div>

          <div style={{ marginTop: 4 }}>
            Once the user has successfully registered and becomes a legitimate user of this platform, who will get a password and username. The user will be solely responsible for the security of the username and password. In addition, each user is solely responsible for all activities and events conducted under their username. You can always change your password according to the instructions. If the user finds any illegal use of the user account or a security breach, please notify this platform immediately. This platform will ensure that your registration information, purchase information submitted during the purchase process are transmitted securely and properly. Use the above information only when you are providing services.
          </div>


          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Refusal to Provide Guarantee</div>

          <div style={{ marginTop: 4 }}>
            The user’s personal use of the network service is at risk. This platform does not make any type of guarantee for this. Whether it is clear or implied, this platform does not guarantee that the service will meet the requirements of the user, nor does it guarantee that the service will not be interrupted, and the timeliness, security and error of the service will be There is no guarantee that it will happen. This platform does not guarantee any information services or transaction processes that are available on the website.
          </div>


          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Limited Liability</div>

          <div style={{ marginTop: 4 }}>
            this platform is not responsible for any direct, indirect, incidental, special or consequential damages that may arise from improper use of network services, purchase of goods online or services of the same type, transactions on the Internet, and illegal use of network services. Or the information transmitted by the user has changed. These behaviors may cause damage to the image of this platform so this platform has proposed the possibility of such damage in advance. In addition, in order to operate the website properly, this platform needs to stop and maintain the website regularly or irregularly. Therefore, please understand the normal service interruption caused by the situation. this platform will try to avoid service interruptions or limit the interruption time to the shortest time. If this platform system with the payment function in this platform crashes or cannot be used normally due to force majeure or other uncontrollable reasons, this platform will not be responsible for your failure to complete or lose relevant information, records, etc. But it will do its utmost to assist in dealing with the aftermath and work hard to protect you from financial losses.
          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Storage and Restriction of User Information</div>

          <div style={{ marginTop: 4 }}>
            this platform has the right to determine whether the user’s behavior complies with the requirements of this platform’s service terms. If the user violates the terms of the service, this platform has the right to discontinue the provision of network services.
          </div>



          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>User Management</div>
          <div style={{ marginTop: 4 }}>
            The user is solely responsible for the content posted. User’s use of the Service is based on all national, local and international legal standards applicable to this platform. Users must follow:
          </div>
          <div style={{ marginTop: 10 }}>
            (1) The transmission of technical data must comply with relevant home country laws and regulations.
          </div>

          <div style={{ marginTop: 10 }}>
            (2) The use of network services is not illegal.
          </div>

          <div style={{ marginTop: 10 }}>
            (3) Do not interfere or confuse network services.
          </div>

          <div style={{ marginTop: 10 }}>
            (4) Comply with all network protocols, regulations, procedures, and practices that use network services.
          </div>
          <div style={{ marginTop: 10 }}>
            Users must undertake not to transmit any illegal, harassing, slanderous, insulting, intimidating, harmful, vulgar, obscene and other information. In addition, users are not allowed to transmit any information that incites others to constitute a criminal act; they cannot transmit information that contributes to domestic adverse conditions and national security; they cannot transmit any information that does not comply with local, national, and international laws. It is prohibited to illegally enter other computer systems without permission. If the user’s behavior does not meet the above mentioned terms of service, this platform will make an independent judgment to immediately cancel the user service account. Users are responsible for their own online behavior. If the user distributes and disseminates reactionary, pornographic or other content that violates national laws and regulations on this platform, the system records of this platform may be used as evidence that the user violates the law.
          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Guarantee</div>
          <div style={{ marginTop: 4 }}>
            The user agrees to protect and maintain the interests of all members of this platform and is responsible for paying the lawyer’s fees caused by the user’s use beyond the scope of services, and the damage compensation fees for violation of the terms of service.
          </div>
          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>End of service</div>
          <div style={{ marginTop: 4 }}>
            The user or this platform may interrupt one or more network services at any time according to actual conditions. This platform does not need to be responsible for any individual or third party and interrupt the service at any time. If the user disagrees with the subsequent modification of the terms or is dissatisfied with the service of this platform the following rights can be exercised:
          </div>
          <div style={{ marginTop: 10 }}>
            (1) Stop using the network services of this platform.
          </div>

          <div style={{ marginTop: 4 }}>
            After the user service is terminated, the user’s right to use the network service is immediately suspended. Since then, the user has no rights and this platform is not obligated to transmit any unprocessed or unfinished services to users or third parties.
          </div>
          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Notice</div>
          <div style={{ marginTop: 4 }}>
            All notices sent to users can be sent via announcements on important pages or by email or regular mail. Modifications to the Terms of Service, changes to the Service, or notices of other important events will be made in this form.
          </div>
          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Ownership of Web Service Content</div>
          <div style={{ marginTop: 4 }}>
            The network service content defined by this platform includes: text, software, sound, pictures, video, graphics, all the contents of the advertisement; the entire content of the email; other information provided by this platform for the user. All of this content is protected by copyright, trademark, label and other property ownership laws. Therefore, users can only use these content under the authorization of this platform and advertisers, and cannot copy, reproduce or create derivative products related to the content. All articles on this platform are copyrighted by the original author and this platform Anyone who needs to reprint the articles on this platform must obtain the authorization of the original author or this platform
          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}> About the Products Sold on This Platform</div>
          <div style={{ marginTop: 4 }}>
            The products sold on this platform will truly publish their parameters, data, historical achievements, and related real introductions, including their advantages and disadvantages. this platform is only responsible for the authenticity and validity of the information transmitted and published, but for the future of the products. How to perform, without any guarantee or guarantee, customers need to objectively and rationally judge the adaptability of the product for any product purchased online. Any loss due to product signals and strategies will not be responsible for any loss of property generated by the customer. Take full responsibility. All online strategy models are intellectual property products and will not be returned once sold. Any dispute arising from the strategy model should be resolved through negotiation. Anyone who solves the problem unilaterally will be invalid.
          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}> Product Risk Tips</div>
          <div style={{ marginTop: 4 }}>
            The investment market is extremely risky. Care must be taken to select products. Any type of automated trading product cannot guarantee its future profitability. All online products will publish their real and effective historical data and performance in detail, but how to perform in the future. It will be subject to many conditions such as market changes, hardware environment and human factors. Any user needs to understand and agree that the automated trading investment products can not guarantee the future profitability of the consensus, and then the purchase model can be selected online. No liability is assumed for any risk of shrinking or losing money due to the quantified trading products.

          </div>
          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}>Disclaimer</div>
          <div style={{ marginTop: 4 }}>
            this platform does not assume legal responsibility for the consequences of abnormal information transmission caused by any reason such as the stock exchange, the futures exchange satellite transmission line, the telecommunications department and the network service provider, but is obliged to actively assist the exchange, the telecommunications department, The network service provider has returned to normal. Users should always avoid and eliminate the information leakage, errors, loss, delay, interruption and other factors that may adversely affect this platform due to the above reasons, so as to protect them from economic losses and reputation losses. The above reasons are to file a claim with this platform In addition, due to earthquakes, typhoons, wars, strikes, government actions, plagues, explosive and epidemic infectious diseases or other major outbreaks, fires caused by non-pervasive causes, disruptions in basic telecommunications networks, and other parties cannot foresee and The cause of force majeure that cannot be prevented or avoided by the consequences will cause the related services to be interrupted. this platform will not bear the losses incurred, but the cause of the service interruption should be promptly notified and actively resolved.

          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}> Dispute Resolution</div>
          <div style={{ marginTop: 4 }}>
            The dispute between the user and this platform due to the performance of this agreement shall be settled through friendly negotiation. If the negotiation fails to resolve, either party shall have the right to submit the dispute to the Beijing Arbitration Commission for arbitration according to the arbitration rules of the Association.

          </div>

          <div style={{ marginTop: 30, color: "#fff", fontWeight: 800, fontSize: 20 }}> Special Statement</div>
          <div style={{ marginTop: 4 }}>

            Any user or website that wants to reprint and disseminate the model strategy information of this platform must indicate the source of the reprint, and the author of the relevant strategy agrees, otherwise it is considered infringement.

          </div>



        </div>
      </div>

      <Food />
    </>
  );
}

